import { getMinimalIncidentId, Incident } from "compass-commons";
import { BehaviorSubject } from "rxjs";
import { IClearIncident } from "../components/commons/alert/model/IClearIncidentAlert";
import IncidentClearRequestError from "../errors/IncidentClearRequestError";
import OperatorGuideService from "../services/OperatorGuideService";
import { OGExecution } from "../model/OG/OGExecution";
import { Widget } from "../model/widget/Widget";

// eslint-disable-next-line import/prefer-default-export
export const clearIncidentCall = (
  executionId: string,
  incident: Incident,
  clearIncidentAlert: BehaviorSubject<IClearIncident>,
  translate: any,
  currentOgExecution: BehaviorSubject<OGExecution>,
  currentExecutionId: BehaviorSubject<string>,
  currentWidgets: BehaviorSubject<Widget[]>
) => {
  OperatorGuideService.clearIncident(incident.id, executionId)
    .then(() => {
      clearIncidentAlert.next({
        icon: incident.icon,
        message: translate("incident.incidentIdentification", {
          incidentId: getMinimalIncidentId(incident.id),
        }),
        priority: incident.priority,
      });
      currentOgExecution.next(null);
      currentExecutionId.next("-1"); // We cleared and we will pick new one
      currentWidgets.next([]);
    })
    .catch((error) => {
      throw new IncidentClearRequestError(error);
    });
};
