import { useEffect, useRef, useState } from "react";
import { AutomaticIncidentConfig } from "../model/incident/AutomaticIncidentConfig";
import TimelineService from "../services/TimelineService";

const useAutomaticIncidentHandling = (
  ogExecutionId: string,
  automaticIncident?: AutomaticIncidentConfig
) => {
  const addAutomaticCommentTimeout = useRef<NodeJS.Timeout | undefined>();
  const [showCloseTask, setShowCloseTask] = useState<boolean>(false);

  const addComment = (commentIndex) => {
    console.log(`Adding comment at index ${commentIndex}`);
    if (commentIndex > automaticIncident.comments.length - 1) {
      setShowCloseTask(true);
      return;
    }
    const automaticComment = automaticIncident.comments[commentIndex];
    TimelineService.addCommentToExecution(ogExecutionId, automaticComment.text)
      .then(() => {
        console.log(
          `Comment ${automaticComment.text} added! Next one will be added in ${automaticComment.nextIn}ms`
        );
        addAutomaticCommentTimeout.current = setTimeout(() => {
          addComment(commentIndex + 1);
        }, automaticComment.nextIn);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setShowCloseTask(false);
    if (automaticIncident) {
      addComment(0);
    }

    return () => {
      if (addAutomaticCommentTimeout.current) {
        clearTimeout(addAutomaticCommentTimeout.current);
      }
    };
  }, [automaticIncident]);

  return { showCloseTask };
};

export default useAutomaticIncidentHandling;
