import React, { useCallback, useMemo, useState } from "react";
import CloseIcon from "@mui/icons-material/CloseRounded";
import {
  getMediaUrl,
  Incident,
  MediaInfo,
  parseDate,
  useI18n,
} from "compass-commons";
import GenericExecutionTask from "./GenericExecutionTask";
import { OGExecutionTask } from "../../../../model/OG/OGExecutionTask";
import { TaskStepState } from "../../../../model/OG/TaskStepState";
import "./genericTaskStep.module.css";
import {
  OGExecutionTaskBlock,
  TagContext,
} from "../../../../model/OG/OGExecutionTaskBlock";
import { BlockTypes } from "../../../../model/OG/BlockTypes";
import { ExecutionTaskType } from "../../../../model/OG/ExecutionTaskType";
import OperatorGuideService from "../../../../services/OperatorGuideService";
import TaskStepFinalizeError from "../../../../errors/TaskStepFinalizeError";
import MultiOptionTaskBlock from "./taskBlocks/MultiOptionTaskBlock";
import { useGlobalContext } from "../../../../contexts/GlobalContext";
import { OGExecutionComment } from "../../../../model/OG/OGExecutionComment";
import PerformAction from "./stepActions/PerformAction";
import ConfirmationAction from "./stepActions/ConfirmationAction";
import MultiOptionAction from "./stepActions/MultiOptionAction";
import { MediaRequestType } from "../../../../model/MediaRequestType";
import GetMediaFileError from "../../../../errors/GetMediaFileError";
import PdfIcon from "../../../../assets/icons/PdfIcon";
import MediaPortal from "../media/MediaPortal";
import DocumentContentType from "../../../../model/DocumentContentType";
import ResourceInteractionBlock from "./taskBlocks/ResourceInteractionBlock";
import { OGExecutionResourceInteraction } from "../../../../model/OG/OGExecutionResourceInteraction";
import { OGExecutionGenericInfo } from "../../../../model/OG/OGExecutionGenericInfo";
import GenericInfoCardTask from "./genericInfoCardTask/GenericInfoCardTask";
import { ResourceCommandAdditionalInfo } from "../../../../model/resource/ResourceCommand";
import { OGExecutionRecordedVideo } from "../../../../model/OG/OGExecutionRecordedVideo";
import RecordedVideoTaskBlock from "./recordedVideoTask/RecordedVideoTaskBlock";
import {
  addCloseTaskClassnameOrEmpty,
  CLOSE_TASK_ID,
  extractSelectedOptionFromDbSearchTask,
  getDateInfo,
  getResourceInteractionSourceText,
} from "../../../../helpers/taskHelpers";
import { OGExecutionAdditionalActivationInfo } from "../../../../model/OG/OGExecutionAdditionalActivationsInfo";
import AdditionalActivationTask from "./additionalActivationTask/AdditionalActivationTask";
import DatabaseSearchDropdownBlock from "./taskBlocks/databaseSearch/DatabaseSearchDropdownBlock";
import DatabaseSearchAction from "./stepActions/DatabaseSearchAction";
import DatabaseSearchResult from "../../../../model/databaseSearch/internal/DatabaseSearchResult";
import DatabaseSearchSelectedOptionDTO from "../../../../model/databaseSearch/DatabaseSearchSelectedOptionDTO";
import { mapInternalSelectedOptionToSelectedOptionDto } from "./taskBlocks/databaseSearch/Util";
import OgTaskCompletionAdditionalDataDTO from "../../../../model/OgTaskCompletionAdditionalDataDTO";
import OptionType from "../../../../model/databaseSearch/internal/OptionType";
import TimelineService from "../../../../services/TimelineService";
import { ProcessEventDTO } from "../../../../model/OG/ProcessEventDTO";
import { clearIncidentCall } from "../../../../helpers/clearHelper";
import useTaskPlaceholder from "./taskPlaceholder/useTaskPlaceholder";
import { AutomaticIncidentConfig } from "../../../../model/incident/AutomaticIncidentConfig";

interface GenericTaskStepProps {
  executionId: string;
  task: any;
  taskState: TaskStepState;
  resourceCommandAdditionalInfo: ResourceCommandAdditionalInfo;
  incident: Incident;
  automaticIncident?: AutomaticIncidentConfig;
}

/**
 * GenericTaskStep component to display task step and its interactions
 * @param props
 * @constructor
 */
const GenericTaskStep = (props: GenericTaskStepProps): JSX.Element => {
  const { t: translate } = useI18n();
  const {
    executionId,
    task,
    taskState,
    resourceCommandAdditionalInfo,
    incident,
    automaticIncident,
  } = props;
  const { setTaskPlaceholderState } = useTaskPlaceholder();
  const { stateService } = useGlobalContext();
  const {
    currentOgExecution,
    mediaLoading,
    clearIncidentAlert,
    currentExecutionId,
    currentWidgets,
  } = stateService;
  let taskType = null;
  let actionBlockType: OGExecutionTaskBlock = null;
  const [stepValue, setStepValue] = useState(
    (task as OGExecutionTask)?.userSelectedEventId
      ? (task as OGExecutionTask)?.userSelectedEventId
      : ""
  );
  const [databaseSearchValue, setDatabaseSearchValue] =
    useState<DatabaseSearchResult>(null);
  const [modalShow, setModalShow] = useState(false);
  const [activeModalId, setActiveModalId] = useState("");
  const [media, setMedia] = useState<MediaInfo>(null);
  const [mediaFile, setMediaFile] = useState(null);

  const activeModal = useCallback(
    (id) => {
      setActiveModalId(id);
    },
    [activeModalId]
  );

  const setTaskTypeAndBlock = (
    blockType: BlockTypes,
    block: OGExecutionTaskBlock
  ) => {
    taskType = blockType;
    actionBlockType = block;
  };

  const multiOptionsCallbackHandler = useCallback((opt: string) => {
    if (opt) {
      setStepValue(opt);
    }
  }, []);

  const databaseSearchCallbackHandler = useCallback(
    (opt: DatabaseSearchResult) => {
      setDatabaseSearchValue(opt);
    },
    []
  );

  const progressTaskAndUpdateTimeline = async (event: ProcessEventDTO) => {
    try {
      setTaskPlaceholderState("pending");

      const engineExecutionResult =
        await OperatorGuideService.progressExecution(executionId, event);

      const registryExecutionResult = await TimelineService.getExecutionById(
        executionId
      );
      setTaskPlaceholderState("success");

      const executionResult = {
        registryResponse: registryExecutionResult,
        engineTaskResponseDTO: engineExecutionResult,
      };

      if (engineExecutionResult && registryExecutionResult) {
        currentOgExecution.next(executionResult);
        setStepValue("");
      }
    } catch (error) {
      setTaskPlaceholderState("error");
      throw new TaskStepFinalizeError(error);
    }
  };

  const finalizeStepHandler = async (eventId: string) => {
    if (task?.id === CLOSE_TASK_ID) {
      clearIncidentCall(
        executionId,
        incident,
        clearIncidentAlert,
        translate,
        currentOgExecution,
        currentExecutionId,
        currentWidgets
      );
    } else {
      await progressTaskAndUpdateTimeline({
        eventId: eventId || stepValue,
        taskId: task?.id,
      });
    }
  };

  const finalizeSearchTaskHandler = async (
    selectedOption: DatabaseSearchResult
  ) => {
    const ogTask = task as OGExecutionTask;
    const ogTaskTypeBlocks = ogTask?.typeBlocks ?? [];

    const selectedEventId =
      selectedOption.type === OptionType.FOUND
        ? ogTaskTypeBlocks.find(
            (block: OGExecutionTaskBlock) => block.type === BlockTypes.Dropdown
          )?.found.id
        : ogTaskTypeBlocks.find(
            (block: OGExecutionTaskBlock) => block.type === BlockTypes.Dropdown
          )?.notFound.id;

    // setStepValue(selectedEventId); TODO RS DELETE

    // Create a DatabaseSearchSelectedOptionDTO from the DatabaseSearchResult
    const selectedOptionDTO: DatabaseSearchSelectedOptionDTO =
      mapInternalSelectedOptionToSelectedOptionDto(selectedOption);

    const taskCompletionAdditionalData: OgTaskCompletionAdditionalDataDTO = {
      selectedOption: selectedOptionDTO,
    };

    await progressTaskAndUpdateTimeline({
      eventId: selectedEventId,
      taskId: task?.id,
      taskCompletionAdditionalData,
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow,no-shadow
  const getMedia = async (mediaFileId): Promise<any> => {
    try {
      const result = await TimelineService.getMediaContentById(mediaFileId);
      if (result) {
        setMediaFile(result);
        mediaLoading.next({ isLoading: false });
      }
      return result;
    } catch (error) {
      throw new GetMediaFileError(error);
    }
  };

  const handleGetMedia = (
    ogExecutionId: string,
    taskId: string,
    fileId: string
  ) => {
    mediaLoading.next({ isLoading: true });
    getMedia(fileId).then(() => {});
  };

  const showModal = useCallback(
    (event) => {
      if (event) {
        setModalShow(!modalShow);
      }
    },
    [modalShow]
  );

  const TextContentMedia = (args: {
    taskId: string;
    mediaDTO: MediaInfo;
    mediaType: MediaRequestType;
  }) => {
    const { mediaDTO } = args;

    function viewHandler(
      contentType: DocumentContentType,
      // eslint-disable-next-line @typescript-eslint/no-shadow,no-shadow
      mediaFileId: string
    ) {
      getMedia(mediaFileId).then((res) => {
        if (res) {
          window.open(getMediaUrl(res, contentType.contentType));
        }
      });
    }

    switch (mediaDTO.extension.toLowerCase()) {
      case DocumentContentType.PDF.toString():
        return (
          <div
            className="document-wrapper"
            style={{ width: "120px", height: "90px" }}
            onClick={() => viewHandler(DocumentContentType.PDF, mediaDTO.id)}
            role="dialog"
          >
            <PdfIcon width="90px" height="90px" />
          </div>
        );
      default:
        return <></>;
    }
  };

  const mapContextTagBlock = (
    taskBody: JSX.Element[],
    block: OGExecutionTaskBlock
  ) => {
    if (block.context === TagContext.Resources) {
      taskBody.push(
        <ResourceInteractionBlock
          key={block.id}
          resources={block.resourceMappingList}
          taskState={taskState}
          id={(task as OGExecutionTask).id}
          resourceCommandAdditionalInfo={resourceCommandAdditionalInfo}
          executionId={executionId}
        />
      );
    }
  };

  const createDropdownBlock = (taskBody: JSX.Element[], tags: string[]) => {
    taskBody.push(
      <DatabaseSearchDropdownBlock
        tags={tags}
        taskState={taskState}
        onChangeCallback={databaseSearchCallbackHandler}
        finalSelectedOption={extractSelectedOptionFromDbSearchTask(
          task as OGExecutionTask,
          taskState
        )}
        incident={incident}
      />
    );
  };

  const addTaskByBlocks = (taskBody: JSX.Element[]) => {
    const ogTask = task as OGExecutionTask;
    const ogTaskTypeBlocks = ogTask?.typeBlocks ?? [];
    const isADropdownTask =
      ogTaskTypeBlocks.find(
        (block: OGExecutionTaskBlock) => block.type === BlockTypes.Dropdown
      ) != null;
    let tags: string[];
    if (isADropdownTask) {
      tags = ogTaskTypeBlocks.find(
        (block: OGExecutionTaskBlock) => block.type === BlockTypes.ContextTag
      )?.tags;
    }
    ogTaskTypeBlocks.forEach((block: OGExecutionTaskBlock) => {
      switch (block.type) {
        case BlockTypes.TextField: {
          taskBody.push(
            <span className="wrap-text" data-cr="generic-block-text">
              {block.payload}
            </span>
          );
          break;
        }
        case BlockTypes.Button:
          setTaskTypeAndBlock(BlockTypes.Button, block);
          break;
        case BlockTypes.MultiOption:
          if (block.options?.length > 0) {
            taskBody.push(
              <MultiOptionTaskBlock
                key={block.id}
                blockId={block.id}
                options={block.options}
                selectedOption={stepValue}
                onChangeCallback={multiOptionsCallbackHandler}
                taskState={taskState}
              />
            );
          }
          setTaskTypeAndBlock(BlockTypes.MultiOption, block);
          break;
        case BlockTypes.Confirmation:
          setTaskTypeAndBlock(BlockTypes.Confirmation, block);
          break;
        case BlockTypes.Dropdown:
          createDropdownBlock(taskBody, tags);
          setTaskTypeAndBlock(BlockTypes.Dropdown, block);
          break;
        case BlockTypes.ContextTag:
          if (!isADropdownTask) {
            mapContextTagBlock(taskBody, block);
            setTaskTypeAndBlock(BlockTypes.ContextTag, block);
          }
          break;
        default:
          break;
      }
    });
  };

  const addMediaAttachments = (taskBody: JSX.Element[]) => {
    if (task as OGExecutionComment) {
      const comment = task as OGExecutionComment;
      if (comment.mediaFileDTOList?.length > 0) {
        const mediaFiles = [];
        comment.mediaFileDTOList.forEach((mediaDto, index) => {
          mediaFiles.push(
            <div
              id={mediaDto.id}
              key={`media-${index}`}
              className="task-step-media-wrapper"
            >
              <div className="media-content">
                <div className="media-header">
                  <CloseIcon
                    sx={{ color: "white", fontSize: "1.2em" }}
                    onClick={() => {
                      throw Error("Not implemented Remove Method");
                    }}
                  />
                </div>
                {mediaDto.thumbnail ? (
                  <img
                    src={`data:${mediaDto.thumbnail.mediaType};base64,${mediaDto.thumbnail.content}`}
                    alt={mediaDto.id}
                    onClick={(event) => {
                      setMedia(mediaDto);
                      showModal(event);
                      activeModal(mediaDto.id);
                      handleGetMedia(
                        task.id,
                        mediaDto.id,
                        MediaRequestType.Comment
                      );
                    }}
                  />
                ) : (
                  <TextContentMedia
                    mediaDTO={mediaDto}
                    mediaType={MediaRequestType.Comment}
                    taskId={task.id}
                  />
                )}
              </div>
              <div className="media-footer">
                <span className="wrap-text">{mediaDto.id}</span>
                {mediaDto.mediaTimestamp && (
                  <span style={{ paddingTop: "5px" }} className="wrap-text">
                    {parseDate(mediaDto.mediaTimestamp)}
                  </span>
                )}
              </div>
            </div>
          );
        });

        taskBody.push(<div className="task-step-media">{...mediaFiles}</div>);
      }
    }
  };

  const addCommentTask = (taskBody: JSX.Element[], automaticComment) => {
    const commentTask = task as OGExecutionComment;
    const comment = automaticComment
      ? commentTask.comment
      : `"${commentTask.comment}"`;
    const extraClass = `${!automaticComment ? " comment" : ""}`;
    taskBody.push(
      <span className={`wrap-text${extraClass}`} data-cr="comment-span">
        {comment}
      </span>
    );
  };

  const addGenericInfo = (taskBody: JSX.Element[]) => {
    const genericInfo = task as OGExecutionGenericInfo;
    taskBody.push(
      <GenericInfoCardTask
        infoFieldDTOs={genericInfo.infoFieldDTOs}
        user={genericInfo.user}
        clearedTime={genericInfo.clearedTimeStamp}
        iconName={genericInfo.icon}
        heading={genericInfo.heading}
        stepId={genericInfo.id}
      />
    );
  };

  const addResourceInteractionTask = (taskBody: JSX.Element[]) => {
    const resourceInteraction = task as OGExecutionResourceInteraction;
    taskBody.push(
      <div className="og-resource-interaction-div">
        <span
          className="wrap-text og-resource-interaction-span"
          data-cr="resource-interaction-span"
        >
          {resourceInteraction.resourceName}
        </span>
        <span
          className="wrap-text og-resource-interaction-source-span"
          data-cr="resource-interaction-source-span"
        >
          {getResourceInteractionSourceText(
            translate,
            resourceInteraction.interactionSource
          )}
        </span>
      </div>
    );
  };

  const addRecordedVideoTask = (taskBody: JSX.Element[]) => {
    const recordedVideo = task as OGExecutionRecordedVideo;
    taskBody.push(
      <RecordedVideoTaskBlock
        videoStartTime={recordedVideo.videoStartTime}
        resources={recordedVideo.resourceMappingList}
        task={task}
        taskState={taskState}
        executionId={executionId}
      />
    );
  };

  const addAdditionalActivationInfo = (taskBody: JSX.Element[]) => {
    const additionalActivationInfo =
      task as OGExecutionAdditionalActivationInfo;
    taskBody.push(
      <AdditionalActivationTask
        key={additionalActivationInfo.id}
        executionId={executionId}
        task={additionalActivationInfo}
        taskState={taskState}
        resourceCommandAdditionalInfo={resourceCommandAdditionalInfo}
      />
    );
  };

  const getGenericExecutionTask = (
    taskBodyList: JSX.Element[]
  ): JSX.Element => {
    addMediaAttachments(taskBodyList);

    return (
      <React.Fragment key="task-body">
        {taskBodyList.map((taskElement) => {
          return (
            <div
              className="task-step-body-container"
              data-cr="task-step-body-container"
              key={`task-body-${taskElement.key}`}
            >
              {taskElement}
            </div>
          );
        })}
      </React.Fragment>
    );
  };

  const getAddCommentBody = (automaticComment = false) => {
    const taskBody: JSX.Element[] = [];
    addCommentTask(taskBody, automaticComment);
    return getGenericExecutionTask(taskBody);
  };

  const getGenericInfoBody = () => {
    const taskBody: JSX.Element[] = [];
    addGenericInfo(taskBody);
    return getGenericExecutionTask(taskBody);
  };

  const getRecordedVideoBody = () => {
    const taskBody: JSX.Element[] = [];
    addRecordedVideoTask(taskBody);
    return getGenericExecutionTask(taskBody);
  };

  const getResourceInteractionBody = () => {
    const taskBody: JSX.Element[] = [];
    addResourceInteractionTask(taskBody);
    return getGenericExecutionTask(taskBody);
  };

  const getAdditionalActivationBody = () => {
    const taskBody: JSX.Element[] = [];
    addAdditionalActivationInfo(taskBody);
    return getGenericExecutionTask(taskBody);
  };

  const getGenericExecutionBody = () => {
    const taskBody: JSX.Element[] = [];
    addTaskByBlocks(taskBody);
    return getGenericExecutionTask(taskBody);
  };

  const getActions = () => {
    const actions = [];
    const ogTask = task as OGExecutionTask;

    if (BlockTypes.Button === taskType) {
      actions.push(
        <PerformAction
          taskState={taskState}
          blockType={actionBlockType}
          actionCallBack={(eventId: string) => finalizeStepHandler(eventId)}
        />
      );
    }

    if (BlockTypes.Confirmation === taskType) {
      actions.push(
        <ConfirmationAction
          taskState={taskState}
          blockType={actionBlockType}
          actionCallBack={(eventId: string) => finalizeStepHandler(eventId)}
          selectedAction={ogTask.userSelectedEventId}
        />
      );
    }

    if (BlockTypes.MultiOption === taskType) {
      actions.push(
        <MultiOptionAction
          taskState={taskState}
          selectedOption={stepValue}
          actionCallBack={(eventId: string) => finalizeStepHandler(eventId)}
        />
      );
    }

    if (BlockTypes.Dropdown === taskType) {
      actions.push(
        <DatabaseSearchAction
          taskState={taskState}
          actionCallBack={(selectedOption: DatabaseSearchResult) =>
            finalizeSearchTaskHandler(selectedOption)
          }
          selectedOption={databaseSearchValue}
        />
      );
    }

    taskType = null;
    actionBlockType = null;
    return <>{...actions}</>;
  };

  /**
   * Used as taks that don't come as a configuration from an OGTemplate or
   * doesn't need the GenericExecutionsWrapper.
   */
  const specialExecutionTasks = useMemo(
    () => ({
      [ExecutionTaskType.GenericInfo]: getGenericInfoBody,
      [ExecutionTaskType.RecordedVideo]: getRecordedVideoBody,
      [ExecutionTaskType.AdditionalActivation]: getAdditionalActivationBody,
    }),
    [task]
  );

  const executionTaskToComponent = useMemo(
    () => ({
      [ExecutionTaskType.Comment]: getAddCommentBody,
      [ExecutionTaskType.ResourceInteraction]: getResourceInteractionBody,
    }),
    [task]
  );

  const buildExecutionTasks = () => {
    const SpecialExecutionTask = specialExecutionTasks[task?.type];

    // This should be refactor. We need to run this in order to initialize the tasktype variable.
    // We are assuming the correct tasktype comes at the end.
    if (!SpecialExecutionTask) getGenericExecutionBody();

    return SpecialExecutionTask ? (
      <SpecialExecutionTask />
    ) : (
      <GenericExecutionTask
        task={task}
        taskState={taskState}
        getBody={
          executionTaskToComponent[task?.type] || getGenericExecutionBody
        }
        automaticIncident={automaticIncident}
      />
    );
  };

  const isDateVisible = () => {
    return (
      task?.type !== ExecutionTaskType.GenericInfo &&
      task?.type !== ExecutionTaskType.RecordedVideo
    );
  };

  const getTaskContainerClassname = useCallback(() => {
    return `task-step-main${addCloseTaskClassnameOrEmpty(task?.id)}`;
  }, [task?.id]);

  return (
    <>
      {/* TODO: change to a higher line the date - needs refactor */}
      {isDateVisible() && task?.id !== CLOSE_TASK_ID && (
        <div className="task-step-date-info">
          {getDateInfo(taskState, task) && (
            <span data-cr="task-step-date-completed">
              {getDateInfo(taskState, task)}
            </span>
          )}
        </div>
      )}
      <div
        className={getTaskContainerClassname()}
        data-cr="generic-task-step-main"
      >
        {buildExecutionTasks()}
      </div>
      <div className="task-step-actions">{getActions()}</div>
      {modalShow && (
        <MediaPortal
          media={media}
          show={modalShow}
          mediaFile={mediaFile}
          portalWrapperId="modal-root"
          onClose={showModal}
          modalId={activeModalId}
          elementsShadowBack={["operator-guide"]}
        />
      )}
    </>
  );
};

export default GenericTaskStep;
