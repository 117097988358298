import React from "react";
import "./operatorGuideHeader.module.css";
import { useI18n } from "compass-commons";
import { IncidentIcon } from "dms-lib";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import { IncidentHeaderDescription } from "../../../model/incident/IncidentHeaderDescription";
import OperatorGuideBadge from "./OperatorGuideBadge";
import DurationCounter from "./DurationCounter";
import VideoMonitoringIcon from "../../../assets/icons/VideoMonitoringIcon";

interface OperatorGuideHeaderProps {
  incidentHeaderDescription: IncidentHeaderDescription;
  className?: string;
}

const OperatorGuideHeader = (props: OperatorGuideHeaderProps): JSX.Element => {
  const { incidentHeaderDescription: incident, className } = props;
  const elementClassName = `${
    (className && `${className} `) || ""
  }operator-guide-header`;

  const { t: translate } = useI18n();

  return (
    <div className={elementClassName}>
      <div className="operator-guide-header__main">
        <div className="operator-guide-header_incident-core">
          <OperatorGuideBadge triggerType={incident.triggerType}>
            <IncidentIcon
              src={incident.iconPath}
              priority={incident.priority}
              size="large"
              className="incident-fit-icon"
            />
          </OperatorGuideBadge>
          <div className="operator-guide-header_incident-core-block">
            {incident.description && (
              <div className="operation--text-title">
                <span>{incident.description}</span>
              </div>
            )}
            {incident.siteName && (
              <div className="operation--text-subtitle">
                <span>
                  {translate("incident.operatorGuideLocationLabel")}{" "}
                  {incident.siteName}
                </span>
              </div>
            )}
          </div>
        </div>
        {(incident.incidentTimestamp || incident.resourceMappingName) && (
          <div className="operator-guide-header_incident-detail">
            {incident.incidentTimestamp && (
              <div className="operation--text-block">
                <AccessTimeOutlinedIcon />
                <DurationCounter targetTimestamp={incident.incidentTimestamp} />
              </div>
            )}
            {incident.resourceMappingName && (
              <div className="operation--text-block">
                <VideoMonitoringIcon />
                <span>
                  {translate("incident.operatorGuideTriggeringDeviceLabel")}{" "}
                  {incident.resourceMappingName}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default OperatorGuideHeader;
