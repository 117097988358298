// General
import React from "react";
import { useI18n } from "compass-commons";
// Styles
import "./OperatorGuideActivationsHeader.scss";
// Contexts
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@mui/material";
import { useGlobalContext } from "../../../../contexts/GlobalContext";

interface OperatorGuideActivationsHeaderProps {
  isChecked: boolean;
  count: number;
}

const OperatorGuideActivationsHeader = ({
  isChecked,
  count,
}: OperatorGuideActivationsHeaderProps): JSX.Element => {
  const { t: translate } = useI18n();
  const { stateService } = useGlobalContext();
  const { incidentAdditionalActivations } = stateService;

  const handleActivations = (_event, checked) => {
    incidentAdditionalActivations.next({
      ...incidentAdditionalActivations.value,
      isAdditionalActivationVisible: checked,
    });
  };

  return (
    <div
      data-cr="operator-guide-activations"
      className="operator-guide-activations"
    >
      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <FormControlLabel
            control={
              <Switch
                checked={isChecked}
                onChange={handleActivations}
                size="small"
              />
            }
            className="operator-guide-activations__text"
            label={`${translate(
              "incident.operatorGuideActivationsLabel"
            )} (${count})`}
            labelPlacement="end"
          />
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default React.memo(OperatorGuideActivationsHeader);
