import React, { useEffect, useState } from "react";
import "./footer.module.css";
import SendIcon from "@mui/icons-material/SendRounded";
import { Incident, LocalizationNS, useI18n } from "compass-commons";
import { Button, TextField } from "dms-lib";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { OGExecutionComment } from "../../../model/OG/OGExecutionComment";
import { useGlobalContext } from "../../../contexts/GlobalContext";
import ClassifyIncident, {
  ClassificationResult,
} from "./classifyIncident/ClassifyIncident";
import { ExecutionTaskType } from "../../../model/OG/ExecutionTaskType";
import { COMMENTS_MAXIMUM_CHARACTERS } from "../../../utils/constants";
import TimelineService from "../../../services/TimelineService";
import { clearIncidentCall } from "../../../helpers/clearHelper";
import { AutomaticIncidentConfig } from "../../../model/incident/AutomaticIncidentConfig";

interface OperatorGuideFooterProps {
  incident: Incident;
  executionId: string;
  showCloseTask: boolean;
  automaticIncident?: AutomaticIncidentConfig;
  hasActiveTask?: boolean;
}

const OperatorGuideFooter = (props: OperatorGuideFooterProps): JSX.Element => {
  const { t: translate } = useI18n();

  const {
    incident,
    executionId,
    showCloseTask,
    automaticIncident,
    hasActiveTask = false,
  } = props;
  const { stateService } = useGlobalContext();
  const {
    currentOgExecution,
    currentExecutionId,
    currentWidgets,
    clearIncidentAlert,
  } = stateService;
  const [textChanging, setTextChanging] = useState(false);
  const [comment, setComment] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [clearIncidentButtonDisabled, setClearIncidentButtonDisabled] =
    useState(hasActiveTask);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const disableCloseTask = automaticIncident && !showCloseTask;

  useEffect(() => {
    setClearIncidentButtonDisabled(hasActiveTask);
  }, [hasActiveTask]);

  const onChangeHandler = (event) => {
    if (event.target.value?.trim().length > 0) {
      setTextChanging(true);
    } else {
      setTextChanging(false);
    }

    setComment(
      event.target.value.substring(0, COMMENTS_MAXIMUM_CHARACTERS - 1)
    );
  };

  const commentAddedCallbackHandler = (newComment: OGExecutionComment) => {
    if (newComment) {
      const execution = { ...currentOgExecution.value };
      const stepsList = execution?.registryResponse?.stepsList;
      if (stepsList) {
        stepsList.push({ ...newComment, type: ExecutionTaskType.Comment });
        execution.registryResponse.stepsList = stepsList;
        currentOgExecution.next(null); // when we do any change on same object, BehaviourSubject does not update its state.
        currentOgExecution.next(execution);
      }
    }
  };

  const addCommentHandler = () => {
    if (comment.length > 0 && comment.length <= 256) {
      setInputDisabled(true);
      TimelineService.addCommentToExecution(executionId, comment)
        .then((res) => {
          commentAddedCallbackHandler(res);
          setComment("");
          setTextChanging(false);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setInputDisabled(false);
        });
    }
  };

  const keyPressHandler = (event) => {
    if (event.charCode === 13 || event.key === "Enter") {
      event.preventDefault();
      addCommentHandler();
    }
  };

  const clearIncident = (clearComment?: string) => {
    const clearCall = () =>
      clearIncidentCall(
        executionId,
        incident,
        clearIncidentAlert,
        translate,
        currentOgExecution,
        currentExecutionId,
        currentWidgets
      );

    if (clearComment) {
      TimelineService.addCommentToExecution(executionId, clearComment)
        .then(clearCall)
        .catch((error) => {
          console.log(error);
        });
    } else {
      clearCall();
    }
  };

  const classifyIncidentCallbackHandler = (val: ClassificationResult) => {
    if (val?.comment) {
      clearIncident(val.comment);
    }
    setOpenDialog(false);
  };

  return (
    <>
      {openDialog && (
        <ClassifyIncident
          openDialog={openDialog}
          isOGCompleted={!hasActiveTask && !disableCloseTask}
          selectionCallback={classifyIncidentCallbackHandler}
          showNotImplementedFeatures={false}
        />
      )}
      <div className="operator-guide-footer-container">
        <div>
          <IconButton
            color="primary"
            onClick={() => setIsExpanded((prev) => !prev)}
            data-cr="og-toggle-footer"
          >
            {isExpanded ? (
              <RemoveCircleOutlineIcon />
            ) : (
              <AddCircleOutlineIcon />
            )}
          </IconButton>
        </div>
        <div
          className={
            isExpanded
              ? "operator-guide-footer"
              : "operator-guide-footer no-display"
          }
        >
          <div className="operator-guide-comment">
            <div className="comment-text">
              <TextField
                data-cr="comment-text-field"
                multiline
                maxRows={3}
                placeholder={translate("incident.commentInputPlaceholder")}
                size="small"
                onChange={onChangeHandler}
                fullWidth
                value={comment}
                onKeyDown={keyPressHandler}
                disabled={inputDisabled}
                iconEnd={
                  <Button
                    dataCr="comment-text-button"
                    color="primary"
                    variant="contained"
                    disabled={!textChanging}
                    onClick={addCommentHandler}
                    size="small"
                    icon
                  >
                    <SendIcon />
                  </Button>
                }
              />
            </div>
          </div>
          <div className="operator-guide-incident-actions">
            <Button
              dataCr="clear-incident-button"
              color="primary"
              variant="contained"
              onClick={() => {
                if (hasActiveTask || disableCloseTask) {
                  setOpenDialog(true);
                } else {
                  clearIncident();
                }
              }}
              className={
                clearIncidentButtonDisabled || disableCloseTask
                  ? "disabled"
                  : ""
              }
              onMouseEnter={() => setClearIncidentButtonDisabled(false)}
              onMouseLeave={() => setClearIncidentButtonDisabled(hasActiveTask)}
            >
              <span>{translate("close", { ns: LocalizationNS.SHARED })}</span>
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default OperatorGuideFooter;
