import { useEffect, useState } from "react";

type Status = "idle" | "pending" | "error" | "success";
const EVENT_NAME = "progress-og-task";

const useTaskPlaceholder = () => {
  const [isLoading, setIsLoading] = useState(false);

  const setTaskPlaceholderState = (status: Status) => {
    window.dispatchEvent(
      new CustomEvent(EVENT_NAME, {
        detail: status,
      })
    );
  };

  useEffect(() => {
    const progressOgTask = ({ detail }: CustomEvent<Status>) => {
      setIsLoading(detail === "pending");
    };

    window.addEventListener(EVENT_NAME, progressOgTask);
    return () => window.removeEventListener(EVENT_NAME, progressOgTask);
  }, []);

  return {
    isLoading,
    setTaskPlaceholderState,
  };
};

export default useTaskPlaceholder;
