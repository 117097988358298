import React from "react";
import { useI18n } from "compass-commons";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import "./taskPlaceholder.module.scss";
import AIAlert from "../../../../../assets/icons/AIAlert";
import useTaskPlaceholder from "./useTaskPlaceholder";

const TaskPlaceholder = ({
  isAiExecution = false,
}: {
  isAiExecution?: boolean;
}) => {
  const { t } = useI18n();
  const { isLoading } = useTaskPlaceholder();

  return isAiExecution ? (
    <Stack my={1} className={isLoading ? "load-in" : "load-out"}>
      <Stack direction="row" alignItems="center" columnGap={1}>
        <AIAlert color="primary" />
        <Typography variant="body2" color="primary" className="loading-text">
          {t("incident.operatorGuide.creatingNewTask")}
        </Typography>
      </Stack>
      <LinearProgress />
    </Stack>
  ) : null;
};

export default TaskPlaceholder;
