import { Tooltip } from "@mui/material";
import { useI18n, LocalizationNS, toDateString } from "compass-commons";
import {
  differenceInDays,
  differenceInSeconds,
  intervalToDuration,
} from "date-fns";
import React, { useEffect } from "react";

interface DurationCounterProps {
  targetTimestamp: number;
}

const DurationCounter = ({ targetTimestamp }: DurationCounterProps) => {
  const { t: translate } = useI18n();
  const [counterText, setCounterText] = React.useState<string>("");
  const [timer, setTimer] = React.useState<NodeJS.Timeout>(null);

  const translateDate = (key: string): string => {
    return translate(`date.${key}`, { ns: LocalizationNS.SHARED });
  };

  const getDateText = (
    dateUnit: number,
    singularKey: string,
    pluralKey: string
  ) => {
    return `${dateUnit} ${translateDate(
      dateUnit === 1 ? singularKey : pluralKey
    )} ${translateDate("timeAgo")}`;
  };

  // Recursive function to return a formatted string of the incident date
  const getFormattedIncidentDate = () => {
    // Create a timer that updates the counter text on every provided interval
    const startTimer = (timeoutInSeconds: number) => {
      if (timer) return; // Prevent multiple timers from running

      const timerId = setTimeout(() => {
        setCounterText(getFormattedIncidentDate());
      }, timeoutInSeconds * 1000);
      setTimer(timerId);
    };

    try {
      // If the difference is 2 days or more, return the date
      const currentTimestamp = Date.now();
      const daysDiff = differenceInDays(currentTimestamp, targetTimestamp);
      if (daysDiff > 1) return toDateString(targetTimestamp);

      const secsDiff = differenceInSeconds(currentTimestamp, targetTimestamp);
      const duration = intervalToDuration({
        start: targetTimestamp,
        end: Date.now(),
      });
      if (duration.days > 0) {
        startTimer((duration.days + 1) * 24 * 60 * 60 - secsDiff);
        return getDateText(duration.days, "day", "days");
      }
      if (duration.hours > 0) {
        startTimer((duration.hours + 1) * 60 * 60 - secsDiff);
        return getDateText(duration.hours, "hour", "hours");
      }
      if (duration.minutes > 0) {
        startTimer((duration.minutes + 1) * 60 - secsDiff);
        return getDateText(duration.minutes, "minute", "minutes");
      }
      // In case of less than a minute, start the timer with less than 60 seconds
      startTimer(60 - secsDiff);
      return `${translateDate("lessThanAMinute")} ${translateDate("timeAgo")}`;
    } catch {
      return toDateString(targetTimestamp);
    }
  };

  useEffect(() => {
    setTimer(null);
    setCounterText(getFormattedIncidentDate());
  }, [targetTimestamp]);

  // Clear the timer when component unmount
  useEffect(() => {
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [timer]);

  return (
    <Tooltip
      title={toDateString(targetTimestamp)}
      disableHoverListener={timer === null}
      arrow
    >
      <span>{counterText}</span>
    </Tooltip>
  );
};

export default DurationCounter;
